import React from 'react';
import './NewProject.css';
import ProjectCard from './Components/ProjectCard/ProjectCard';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const projects = [
  { 
    image: require('./Assets/Image/img2.jpeg'),
    title: 'The Highway Capital',
    developer: 'Brick2Brick',
    location: 'Nandi kandi, Hyderabad',
    details: 'Residential and commercial plots',
    price: '₹ 52.60 L - 3.20 Cr',
    Link: '/the-highway-capital'
  },
  {
    image: require('./Assets/Image/Okafield.jpg'),
    title: 'Oak Field',
    developer: 'Brick2Brick',
    location: 'Machireddipally, Hyderabad',
    details: 'Plots and Farm Lands',
    price: '₹ 1.93 Cr - 4.49 Cr',
    Link: '/oakfield'
  },
  {
    image: require('./Assets/Image/westernhills.jpg'),
    title: 'The Western Hills',
    developer: 'Brick2Brick',
    location: 'Gachibowli, Hyderabad',
    details: 'Plots',
    price: '₹ 1.56 Cr',
    Link: '/WesternHills'
  },
];

function Project() {
  return (
    <div className="app">
      <h1>Our Projects</h1>
      <div className="projects carousel-view">
        <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
          {projects.map((project, index) => (
            <div key={index}>
              <ProjectCard project={project} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="projects grid-view">
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
}

export default Project;
