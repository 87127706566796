import React from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaYoutube, FaSearchLocation,  } from 'react-icons/fa'; // Make sure to create corresponding CSS if needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const Footer = () => {
    return (
        <footer className="footer-sec pt-5">
            <div className="container">
                <div className="footer-quick-links-container">
                    <div className="row">
                        <div className="col-sm-6 col-md-3">
                            <div className="footer-quick-links-box foot-popular-searches">
                                <h3>Property in Top State</h3>
                                <ul>
                                    <li>Property in Telangana</li>
                                    {/* <li><a href="#">Plots in Noida</a></li>
                  <li><a href="#">Offices for Rent</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <div className="footer-quick-links-box foot-popular-searches">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li>Property Service</li>
                                    <li>B2B Faqs</li>
                                    <li>About us</li>
                                    <li><a href="/Enquire">Contact us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="footer-quick-links-box foot-popular-searches">
                                <h3>Office Address</h3>
                                <ul>
                                    <li>#Flat No 501 & 506,Manjeera Majestic Commercial Complex,KPHB Colony ,Hyderabad.</li>
                                    <li><a href='https://maps.app.goo.gl/gCThB7UBt7kA5uhb8'><FontAwesomeIcon
                                     icon="fa-solid fa-location-dot" /><FaSearchLocation/> location</a></li>
                                   
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                        
                            <div className="footer-quick-links-box foot-popular-searches">
                            <h3>Social Media links</h3>
                            <div className="hero-social-links">
                            <a href="https://www.facebook.com/Brick2Brickofficial" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                            <a href="https://x.com/brickbrick21915" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                            <a href="https://www.linkedin.com/company/brick2-brick/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                            <a href="https://www.instagram.com/Brick2Brickofficial/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                            <a href="https://www.youtube.com/@Brick2BrickOfficial" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                        </div>
                                <h3>Subscribe</h3>
                                <div className="subscribe-box">
                                    <div className="subscribe-input-box">
                                        <input id="subscribe-input" type="text" className="form-control" placeholder="Your Email Address*" />
                                        <input type="submit" value="Subscribe" />
                                    </div>
                                    {/* <div className="app-download-box">
                                  
                                            <img src="https://Brick2Brick.com/assets/nw_images/google-play-icon.png" alt="img" />
                                        
                                        
                                            <img src="https://Brick2Brick.com/assets/nw_images/apple-store-icon.png" alt="img" />
                                       
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="row">
                        <div className="footer-menu-box">
                            <ul>
                                <li><a href="https://Brick2Brick.com/sitemap">Sitemap</a></li>
                                <li><a href="https://Brick2Brick.com/terms">Term &amp; Condition</a></li>
                                <li><a href="https://Brick2Brick.com/privacy-policy">Privacy Policy</a></li>
                                <li><a href="#">Careers</a></li>
                                <li><a href="#">Unsubscribe</a></li>
                                <li><a href="https://Brick2Brick.com/news">News</a></li>
                                <li><a href="#">Help Center</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="bottom-footer">
            <div className="bottom-footer-content-box">
                       
                       <h6>Copyright 2024. Brick2Brick CONSULTING PVT LTD. | All Right Reserved.</h6>
                  
                       
                   
               </div>
            </div>
        </footer>
    );
};

export default Footer;
