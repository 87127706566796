import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const Careers = () => {
  const [showTelecaller, setShowTelecaller] = useState(false);
  const [showBDM, setShowBDM] = useState(false);
  const [showBDE, setShowBDE] = useState(false);

  const toggleTelecaller = () => setShowTelecaller(!showTelecaller);
  const toggleBDM = () => setShowBDM(!showBDM);
  const toggleBDE = () => setShowBDE(!showBDE);

  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h1>Careers</h1>
          <p>Join our team and help us grow. We are looking for passionate and dedicated individuals.</p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Telecaller</Card.Title>
              <Button onClick={toggleTelecaller} variant="danger">
                {showTelecaller ? 'Hide Details' : 'Show Details'}
              </Button>
              {showTelecaller && (
                <Card.Text>
                  <strong>Location:</strong> Hyderabad<br />
                  <strong>Experience:</strong> 0-2 years<br />
                  <strong>Job Type:</strong> Full-time<br /><br />
                  <strong>Job Description:</strong>
                  <ul>
                    <li>Handle inbound and outbound calls in a professional manner.</li>
                    <li>Respond to customer inquiries and resolve issues.</li>
                    <li>Maintain customer records and update necessary information.</li>
                    <li>Achieve daily, weekly, and monthly targets.</li>
                  </ul>
                  <strong>Requirements:</strong>
                  <ul>
                    <li>Excellent communication skills.</li>
                    <li>Prior experience in a similar role is preferred.</li>
                    <li>Ability to handle rejection and stress.</li>
                    <li>Strong organizational skills.</li>
                  </ul>
                  <p>If you are interested in this position, please send your resume to <a href="mailto:katakam.tejasri@brick2brick.com">HR</a>.</p>
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Business Development Manager</Card.Title>
              <Button onClick={toggleBDM} variant="danger">
                {showBDM ? 'Hide Details' : 'Show Details'}
              </Button>
              {showBDM && (
                <Card.Text>
                  <strong>Location:</strong> Hyderabad<br />
                  <strong>Experience:</strong> 0-5 years<br />
                  <strong>Job Type:</strong> Full-time<br /><br />
                  <strong>Job Description:</strong>
                  <ul>
                    <li>Develop and implement growth strategies for the company.</li>
                    <li>Build and maintain relationships with clients and partners.</li>
                    <li>Identify new business opportunities and market trends.</li>
                    <li>Manage the business development team and provide guidance.</li>
                  </ul>
                  <strong>Requirements:</strong>
                  <ul>
                    <li>Proven experience as a Business Development Manager.</li>
                    <li>Strong leadership and communication skills.</li>
                    <li>Ability to develop and maintain client relationships.</li>
                    <li>Analytical and problem-solving skills.</li>
                  </ul>
                  <p>If you are interested in this position, please send your resume to <a href="mailto:katakam.tejasri@brick2brick.com">HR</a>.</p>
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Business Development Executive</Card.Title>
              <Button onClick={toggleBDE} variant="danger">
                {showBDE ? 'Hide Details' : 'Show Details'}
              </Button>
              {showBDE && (
                <Card.Text>
                  <strong>Location:</strong> Hyderabad<br />
                  <strong>Experience:</strong> 0-3 years<br />
                  <strong>Job Type:</strong> Full-time<br /><br />
                  <strong>Job Description:</strong>
                  <ul>
                    <li>Assist in developing business strategies and initiatives.</li>
                    <li>Conduct market research to identify new opportunities.</li>
                    <li>Support the business development team in client meetings.</li>
                    <li>Prepare reports and presentations for management.</li>
                  </ul>
                  <strong>Requirements:</strong>
                  <ul>
                    <li>Bachelor’s degree in Business Administration or related field.</li>
                    <li>Excellent communication and negotiation skills.</li>
                    <li>Proactive and goal-oriented mindset.</li>
                    <li>Strong analytical skills.</li>
                  </ul>
                  <p>If you are interested in this position, please send your resume to <a href="mailto:katakam.tejasri@brick2brick.com">HR</a>.</p>
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Careers;
