import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import oak1 from './Assets/images/OAK FIELD/Oka field.jpg';
import layout1 from './Assets/images/OAK FIELD/Oak Field__page-0003.jpg';
import locationImg from './Assets/images/OAK FIELD/location.jpg';
import tree from './Assets/images/OAK FIELD/tree.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons'; // Import specific icons
import entry from './Assets/amenities/srb_entrance_arch.png';
import road from './Assets/amenities/srb_bt_roads1.png';
import security from './Assets/amenities/srb_247_security.png';
import run from './Assets/amenities/srb_jogging_track1.png';
import pool from './Assets/amenities/srb_half_size_swimming-pool1.png';
import cycle from './Assets/amenities/srb_cycling_track1.png';
import room from './Assets/amenities/srb_luxury_rooms1.png';
import amenities from './Assets/amenities/srb_amenities.png';
import rera from './Assets/amenities/srb_approved.png';
import investment from './Assets/amenities/srb_growth.png';
import Friendly from './Assets/amenities/srb_bio.png';
import resort from './Assets/amenities/srb_miyawaki_forest1.png';

import './OakField.css'; // Ensure your custom CSS file is imported
import EMICalculator from './Components/Emi/EmiCalculator';
const testimonials = [
    {
        quote: "Brick2Brick made the process of buying a plot seamless and stress-free. Highly recommend!",
        author: "Pawan Kumar"
    },
    {
        quote: "The team at Brick2Brick is professional and reliable. We are very happy with our purchase.",
        author: "Abdul Kalam"
    },
    {
        quote: "Excellent service and great quality plots. Couldn't be happier with our investment.",
        author: "Narendra"
    }
];
const data = [
    { id: 1, name: 'DTCP Plots', Size: 150, Price: '₹ 11,999', value: "₹ 5 Lakhs" },
    { id: 2, name: 'Farm land', Size: 242, Price: '₹ 8,999', value: '₹ 5 Lakhs' },
    
  ];
const OakField = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const openDirections = () => {
        window.open("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3801.5114097678397!2d77.9791244!3d17.6732852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbff42098ae613%3A0xb3600156fae741c5!2sOAK%20FIELD!5e0!3m2!1sen!2sin!4v1720601637170!5m2!1sen!2sin"  );
    };
    return (
        <div className="default-home">
            <section id="default-home-hero" className="hero-section py-5">
                <div className="hero-content container">
                    <div className="row">
                        <div className="hero-texts col-md-6 col-12">
                            <div className="hero-headings">
                                <h1 className="display-4">
                                    Welcome to
                                    <span className="d-block display-3 company-name"> Oak Field</span>
                                </h1>
                                <div className="company-description">
                                    <p>A well-lit gated community offering a sense of secure exclusiveness, Oak Field is characterized by fine features. The 100 acres are dedicated to serene living surrounded with mango plantations which are cared for by a team of qualified horticulturists. What stands out as you step in: quality infrastructure and facilities, eco-friendly measures and well-kept green areas.</p>
                                </div>
                            </div>
                        </div>
                        <div className="hero-image col-md-6 col-12">
                            <Carousel
                                showThumbs={false}
                                infiniteLoop
                                autoPlay
                                interval={3000}
                                showStatus={false}
                            >
                                {[oak1].map((src, index) => (
                                    <div key={index}>
                                        <img src={src} alt={`img${index + 1}`} className="img-fluid" />
                                        <p className="legend"></p>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            
           

            <section id="amenities-section" className="amenities-section py-5">
                <div className="container text-center">
                    <h2>Project Highlights</h2>
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={amenities} alt="50+ Amenities" />
                                <h5>50+ Amenities</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={investment} alt="invsetment Zone" />
                                <h5>investment Zone</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={Friendly} alt="Sustainable & Eco Friendly" />
                                <h5>Sustainable & Eco Friendly</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={rera} alt="RERA Approved" />
                                <h5>RERA Approved</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="default-home-layout" className="layout-section py-5">
                <div className="container text-center">
                    <h2>Project Layout</h2>
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-12">
                            <img src={layout1} alt="Layout 1" className="img-fluid" onClick={handleShow} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="table-container">
                <h2>Price</h2>
                    <table className="table table-striped table-bordered table-hover">
                        
                        <thead className="thead-dark">
                            <tr>
                                <th>ID</th>
                                <th>Unit Type</th>
                                <th>Size (SQ.Yrds)</th>
                                <th>Price (SQ.Yrds)</th>
                                <th>Booking Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                    <td data-label="ID">{item.id}</td>
                                    <td data-label="Unit Type">{item.name}</td>
                                    <td data-label="Size (SQ.Yrds)">{item.Size}</td>
                                    <td data-label="Price (SQ.Yrds)">{item.Price}</td>
                                    <td data-label="Booking Amount">{item.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <section id="logos-section" className="logos-section py-5">
                <div className="container text-center">
                    <h2>Our Locations</h2>
                    <h5>SURROUNDED BY EVER-GROWING DEVELOPMENTS</h5>
                    <div className="row">
                    <div className="col-lg-8 col-12">
                            <img src={locationImg} alt="Location Map" className="img-fluid responsive-img" />
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="location-points">
                            <Button variant="danger" onClick={openDirections}>Get Directions</Button>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 20 MIN FROM MUTHANGI ORR JUNCTION
                                </h4>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 15 MIN TO WAXEN UNIVERSITY
                                </h4>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 20 MIN FROM ORDINANCE FACTORY
                                </h4>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 10 MIN FROM MRF MANUFACTURING UNIT
                                </h4>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 10 MIN FROM IIT HYDERABAD
                                </h4>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 20 MIN FROM BHARAT DYNAMICS LIMITED
                                </h4>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 20 MIN TO GITAM UNIVERSITY
                                </h4>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 15 MIN TO PROPOSED 12,000 ACRES NIMZ (NATIONAL INVESTMENT MANUFACTURING ZONE)
                                </h4>
                                <h4>
                                    <FontAwesomeIcon icon={faLeaf} style={{ color: "#148016" }} /> 10 MINS FROM REGIONAL RING ROAD (RRR)
                                </h4>
                            </div>
                            <div className="col-lg-8 col-12">
                                <img src={tree} alt="Location Map" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="amenities-section" className="amenities-section py-5">
                <div className="container text-center">
                    <h2>Project Amenities</h2>
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={entry} alt="Grand Entrance Arch" />
                                <h5>Grand Entrance Arch</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={security} alt="Level 5 Security" />
                                <h5>Security</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={room} alt="Luxury Rooms" />
                                <h5>Luxury Rooms</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={pool} alt="Swimming Pool" />
                                <h5>Swimming Pool</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={road} alt="Wide BT Roads" />
                                <h5>40FT Wide BT Roads</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={run} alt="Jogging Track" />
                                <h5>Jogging Track</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={cycle} alt="Cycling Track" />
                                <h5>Cycling Track</h5>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="amenity">
                                <img src={resort} alt="resort" />
                                <h5>plantations </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials-section">
                <div className="container">
                    <h2 className="section-title">What Our Clients Say</h2>
                    <Carousel
                        showThumbs={false}
                        infiniteLoop
                        autoPlay
                        interval={5000}
                        showStatus={false}
                    >
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial">
                                <p className="testimonial-quote">"{testimonial.quote}"</p>
                                <p className="testimonial-author">- {testimonial.author}</p>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </section>
            <section>
                <EMICalculator />
            </section>
            <Modal show={showModal} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Project Layout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={layout1} alt="Layout 1" className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default OakField;

