
import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './Blog.css';

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const targetDate = new Date('2024-08-31T00:00:00').getTime();
    
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference < 0) {
        clearInterval(interval);
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mtdludn', 'template_d7xz64b', e.target, 'fWM_huievHDIpfX2F')
      .then((result) => {
          console.log(result.text);
          setMessage('Subscription successful!');
      }, (error) => {
          console.log(error.text);
          setMessage('Subscription failed. Please try again.');
      });

    setEmail('');
  };

  return (
    <div className="coming-soon">
      <div className="container">
        <h1>Coming Soon</h1>
        <p>Our blog is launching soon. Stay tuned for updates!</p>
        <div className="countdown">
          {`${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}
        </div>
        <form className="subscribe" onSubmit={handleSubmit}>
          <input 
            type="email" 
            name="user_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email" 
            required 
          />
          <button type="submit">Subscribe</button>
        </form>
        {message && <p className="message">{message}</p>}
       
      </div>
    </div>
  );
};

export default ComingSoon;
