// src/AparnaCarousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from '../../Assets/images/Sliders/img1.jpg';
import img2 from '../../Assets/images/Sliders/img2.jpg';
import img3 from '../../Assets/images/Sliders/img3.jpg';
import './Slider.css';

const projectLinks = [
    '/the-highway-capital',
    '/oakfield',
    '/WesternHills',
];

const SliderCarousel = () => {
  return (
    <div className="Slider-carousel-container">
      <Carousel showThumbs={false} infiniteLoop autoPlay>
        {projectLinks.map((link, index) => (
          <div key={index} className={`mobile-image-${index + 1}`} style={{ position: 'relative' }}> {/* Add mobile-specific classes */}
            <img src={[img1, img2, img3][index]} alt={`Slider Project ${index + 1}`} />
            <a href={link} className="explore-button">
              Explore Now
            </a>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderCarousel;

