import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Container, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../Assets/images/logo/logo.png';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import DefaultHome from '../Contact/Enquire';

const CustomNavbar = () => {
  const [showModal, setShowModal] = useState(false);

  const handleEnquireClick = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Navbar collapseOnSelect expand="sm" bg="light" variant="light" className="custom-navbar">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} className="brand-logo" alt="Brand Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto nav-links">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <NavDropdown title="Our Projects" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/new-projects">New Projects</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/brochures">Brochures</Nav.Link>
              <Nav.Link as={Link} to="/careers">Careers</Nav.Link>
              <Nav.Link as={Link} to="/partner-with-us">Partner with Us</Nav.Link>
              <Nav.Link as={Link} to="/blog">Blog</Nav.Link>
              <Nav.Link as={Link} to="/about-us">About Us</Nav.Link>
              <Nav.Link as={Link} to="/emi-calculator">EMI Calculator</Nav.Link>
              <Nav.Link href="tel:9063223242">
                <FontAwesomeIcon icon={faPhone} style={{ color: 'red', marginRight: '5px' }} />9063223242
              </Nav.Link>
              <Nav.Link as={Link} to="#" onClick={handleEnquireClick}>
                <button className="button-24">Enquire Now</button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DefaultHome />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomNavbar;

