import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import './Home.css';
import b2b1 from '../../Assets/images/logo-short.png';

import img1 from '../../Assets/Gallery/Office/IMG-20240724-WA0003.jpg';
import img2 from '../../Assets/Gallery/Office/IMG-20240724-WA0019.jpg';
import img3 from '../../Assets/Gallery/OakField/gate.jpg';
import img5 from '../../Assets/Gallery/OakField/land.jpg';
import img4 from '../../Assets/Gallery/OakField/okafield.jpg';
import img6 from '../../Assets/Gallery/OakField/okafield2.jpg';


import Project from '../../NewProject';
import HomePage from './HomeSlider';
import DefaultHome from '../Contact/Enquire';

const testimonials = [
    {
        quote: "Brick2Brick made the process of buying a plot seamless and stress-free. Highly recommend!",
        author: "Pawan Kumar"
    },
    {
        quote: "The team at Brick2Brick is professional and reliable. We are very happy with our purchase.",
        author: "Abdul Kalam"
    },
    {
        quote: "Excellent service and great quality plots. Couldn't be happier with our investment.",
        author: "Narendra"
    }
];

const videoIds = [
    'y56OTSWfCGw',
    'lOw4VYDirFA',
    'V_DU_x67DOY'
];

const galleryImages = [
    img1, img2, img3, img4,img5,img6

];

function Home() {
    const [showModal, setShowModal] = useState(false);
   

    const handleImageClick = (src) => {
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const handleEnquireClick = () => setShowModal(true);

    return (
        <div className="default-home">
            <section>
                <HomePage />
            </section>
            <section id="default-home-hero" className="hero-section">
                <div className="hero-content container">
                    <div className="row align-items-center">
                        <div className="hero-texts col-md-6 d-flex flex-column justify-content-center">
                            <div className="hero-headings">
                                <h1 className="display-4">
                                    Welcome to
                                    <h2 className="d-block display-3 company-name">Brick2Brick</h2>
                                </h1>
                                <div className="company-description">
                                    <p>"Brick2Brick" is a Reliable, Vibrant, and Eminent real estate company dedicated to transforming the landscape of urban living. Our focus is on achieving higher standards in real estate project management and customer relationship management. Brick2Brick is renowned for its meticulous planning, innovative approach to ventures, and commitment to quality. We aim to set new benchmarks in the industry by delivering projects that combine luxury, affordability, and sustainability.</p>
                                </div>
                            </div>
                        </div>
                        <div className="hero-image col-md-6 d-flex justify-content-center">
                            <img src={b2b1} alt='' className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="follow-us-section">
                    <div className="follow-us-text">
                        <h3>FOLLOW US</h3>
                        <div className="vertical-line"></div>
                    </div>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/Brick2Brickofficial" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                        <a href="https://x.com/brickbrick21915" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                        <a href="https://www.linkedin.com/company/brick2-brick/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                        <a href="https://www.instagram.com/Brick2Brickofficial/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        <a href="https://www.youtube.com/@Brick2BrickOfficial" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                    </div>
                </div>
            </section>
            <section>
                <Project />
            </section>
            <section className="gallery-section">
                <div className="container">
                    <h2 className="section-title">Our Gallery</h2>
                    <div className="gallery">
                        {galleryImages.map((src, index) => (
                            <img
                                key={index}
                                src={src}
                                alt={`gallery-img${index + 1}`}
                                className="gallery-image"
                                onClick={() => handleImageClick(src)}
                            />
                        ))}
                    </div>
                    <div className="carousel-gallery">
                        <Carousel
                            showThumbs={false}
                            infiniteLoop
                            autoPlay
                            interval={3000}
                            showStatus={false}
                        >
                            {galleryImages.map((src, index) => (
                                <div key={index}>
                                    <img src={src} alt={`carousel-img${index + 1}`} className="responsive-image" onClick={() => handleImageClick(src)} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </section>
            <section className="videos-section">
                <div className="container">
                    <h2 className="section-title">Our Videos</h2>
                    <Carousel
                        showThumbs={false}
                        infiniteLoop
                        autoPlay
                        interval={5000}
                        showStatus={false}
                    >
                        {videoIds.map((videoId, index) => (
                            <div key={index} className="video-carousel-item">
                                <div className="video-responsive">
                                    <iframe
                                        width="560"
                                        height="315"
                                        src={`https://www.youtube.com/embed/${videoId}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title={`video${index + 1}`}
                                    ></iframe>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </section>
            <section className="testimonials-section">
                <div className="container">
                    <h2 className="section-title">What Our Clients Say</h2>
                    <Carousel
                        showThumbs={false}
                        infiniteLoop
                        autoPlay
                        interval={5000}
                        showStatus={false}
                    >
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial">
                                <p className="testimonial-quote">"{testimonial.quote}"</p>
                                <p className="testimonial-author">- {testimonial.author}</p>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </section>

            <div className="fixed-enquire-button">
                <button className="button-24" onClick={handleEnquireClick}>Contact Us</button>
            </div>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Body>
                    <DefaultHome />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Home;
