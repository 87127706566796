import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomNavbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import DefaultHome from './Components/Contact/Enquire';
import Footer from './Components/Footer/Footer';
import AboutSection from './Components/About/About';
import BrochureList from './Components/Brochure/Brochure';
import BusinessAssociateForm from './Components/PartnerUs/PartnerWithUs';
import Careers from './Components/Career/Career';


import THC from './TheHighwayCapital';
import Project from './NewProject';
import OakField from './OakField';
import Hills from './WesternHills';
import EMICalculator from './Components/Emi/EmiCalculator';
import ComingSoon from './Components/Blog/Blog';





const Pages = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleEnquireClick = () => {
    setShowContactForm(true);
  };

  return (
    <Router>
      <CustomNavbar onEnquireClick={handleEnquireClick} />
      
      <Routes>
      
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutSection />} />
        <Route path="/enquire" element={<DefaultHome />} />
        <Route path="/brochures" element={<BrochureList />} />
        <Route path="/new-projects" element={<Project />} />
        <Route path="/the-highway-capital" element={<THC />} />
        <Route path="/oakfield" element={<OakField />} />
        <Route path="/partner-with-us" element={<BusinessAssociateForm />} />
        <Route path="*" element={<Home />} />
        <Route path="/careers" element={<Careers />} />
        <Route path='/westernhills' element={<Hills />} />
        <Route path='/emi-calculator' element={<EMICalculator/>} />
        <Route path='/blog' element={<ComingSoon/> }/>
      </Routes>
      {showContactForm && <DefaultHome />}
     
      <Footer />
    </Router>
  );
};

export default Pages;





