import img1 from '../../Assets/images/logo-short.png'
import React from 'react';
import './About.css'; 

const AboutSection = () => {
  return (
    <section className="defaultpadd">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 about-img">
            <img src={img1} alt="Brick2Brick" className="w-100" loading="lazy" />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <h2>About <span>Brick2Brick</span></h2>
              <p>
                "Brick2Brick" is a Reliable, Vibrant, and Eminent real estate company dedicated to transforming the landscape of urban living. Our focus is on achieving higher standards in real estate project management and customer relationship management. Brick2Brick is renowned for its meticulous planning, innovative approach to ventures, and commitment to quality. We aim to set new benchmarks in the industry by delivering projects that combine luxury, affordability, and sustainability.
              </p>
              <p>
                Our core values revolve around transparency, integrity, and customer satisfaction. At Brick2Brick, we are committed to providing our customers with:
              </p>
              <ul>
                <li>High-quality luxurious residential plots.</li>
                <li>Premium quality venture plots at affordable prices.</li>
                <li>Comprehensive amenities within our developments.</li>
                <li>24/7 professional customer support.</li>
                <li>Advanced security systems equipped with modern technology.</li>
                <li>A green and clean environment that promotes healthy living.</li>
              </ul>
              <p>
                With a team of experienced professionals and a customer-centric approach, Brick2Brick strives to deliver projects that exceed expectations. Our developments are designed to provide a harmonious blend of comfort, convenience, and elegance.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="border-box">
              <h2><i className="fas fa-eye"></i> Vision</h2>
              <p>Our vision is to create a better tomorrow for all individuals, families, and communities by redefining lifestyle standards through building world-class real estate solutions. We aim to be a leader in the industry, known for our innovative and sustainable developments.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="border-box">
              <h2><i className="fas fa-bullseye"></i> Mission</h2>
              <p>Our mission is to engage in issues that matter to people through an uncompromising commitment to creating an outstanding living environment. We are dedicated to fostering communities that thrive on quality, comfort, and sustainability.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="border-box">
              <h2><i className="fas fa-handshake"></i> Values</h2>
              <p>At Brick2Brick, our core values include transparency, integrity, and a customer-first approach. We believe in building lasting relationships with our clients based on trust and mutual respect. We also prioritize innovation and creativity in our projects to deliver unique and exceptional results.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="border-box">
              <h2><i className="fas fa-users"></i> Community</h2>
              <p>We are committed to creating communities that offer a balanced lifestyle, combining modern amenities with a serene environment. Our developments are designed to foster social interactions and a sense of belonging.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="border-box">
              <h2><i className="fas fa-leaf"></i> Sustainability</h2>
              <p>Brick2Brick is dedicated to sustainable development practices. We incorporate eco-friendly materials and energy-efficient solutions in our projects to minimize our environmental impact and promote a healthier planet.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="border-box">
              <h2><i className="fas fa-award"></i> Excellence</h2>
              <p>We strive for excellence in every aspect of our work. From design and construction to customer service, we are committed to delivering superior quality and unparalleled value to our clients. Our goal is to continuously improve and innovate to meet the evolving needs of our customers and the industry.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;

