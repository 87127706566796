import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './EmiCalculator.css';

const EMICalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const [emi, setEMI] = useState(null);

  const handleCalculateEMI = (e) => {
    e.preventDefault();
    const principal = parseFloat(loanAmount);
    const rate = parseFloat(interestRate) / 100 / 12;
    const time = parseFloat(loanTenure) * 12;
    const emiValue = (principal * rate * Math.pow(1 + rate, time)) / (Math.pow(1 + rate, time) - 1);
    setEMI(emiValue.toFixed(2));
  };

  const handleLoanAmountChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setLoanAmount(value);
    }
  };

  const handleInterestRateChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setInterestRate(value);
    }
  };

  const handleLoanTenureChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setLoanTenure(value);
    }
  };

  return (
    <div className="emi-calculator">
      <h2 className="text-center mb-4">EMI Calculator</h2>
      <Form onSubmit={handleCalculateEMI}>
        <Form.Group className="mb-3">
          <Form.Label>Loan Amount</Form.Label>
          <Form.Control type="number" value={loanAmount} onChange={handleLoanAmountChange} placeholder="Enter loan amount" required />
        </Form.Group>
        <Row className="mb-3">
          <Col>
            <Form.Label>Interest Rate (%)</Form.Label>
            <Form.Control type="number" value={interestRate} onChange={handleInterestRateChange} placeholder="Enter interest rate" required />
          </Col>
          <Col>
            <Form.Label>Loan Tenure (years)</Form.Label>
            <Form.Control type="number" value={loanTenure} onChange={handleLoanTenureChange} placeholder="Enter loan tenure in years" required />
          </Col>
        </Row>
        <Button variant="danger" type="submit" className="w-60 text-center">
          Calculate EMI
        </Button>
      </Form>
      {emi && <p className="emi-result mt-4 text-center">Your EMI: ₹ {emi}</p>}
    </div>
  );
};

export default EMICalculator;
