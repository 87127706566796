import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Enquire.css'; // Import the CSS file

const DefaultHome = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    message: '',
    interestedIn: '',
    propertyValue: '',
    securityCode: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mtdludn', 'template_d7xz64b', e.target, 'fWM_huievHDIpfX2F')
      .then((result) => {
        alert('Message sent successfully!');
        setFormData({
          name: '',
          
          email: '',
          mobile: '',
          message: '',
          interestedIn: '',
          propertyValue: '',
          securityCode: '',
        });
      }, (error) => {
        alert('Failed to send message, please try again later.');
      });
  };

  return (
    <div className="default-home">
      <section id="contact-form" className="contact-form-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h2>Contact Us</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>You are Interested in</label><br />
                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className="btn btn-outline-danger">
                      <input type="radio" name="interestedIn" id="plots" value="Plots" checked={formData.interestedIn === 'Plots'} onChange={handleChange} /> PLOTS
                    </label>
                    <label className="btn btn-outline-danger">
                      <input type="radio" name="interestedIn" id="farm plots" value="Farm Plots" checked={formData.interestedIn === 'Farm Plots'} onChange={handleChange} /> Farm Plots
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label>Value of the Property</label><br />
                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className="btn btn-outline-danger">
                      <input type="radio" name="propertyValue" id="20-40" value="20-50 Lakhs" checked={formData.propertyValue === '20-50 Lakhs'} onChange={handleChange} /> 20 - 50 Lakhs
                    </label>
                    <label className="btn btn-outline-danger">
                      <input type="radio" name="propertyValue" id="40-80" value="50-65 Lakhs" checked={formData.propertyValue === '50-65 Lakhs'} onChange={handleChange} /> 50 - 65 Lakhs
                    </label>
                   
                    <label className="btn btn-outline-danger">
                      <input type="radio" name="propertyValue" id="above-80" value="Above 80 Lakhs" checked={formData.propertyValue === 'Above 80 Lakhs'} onChange={handleChange} /> Above 80 Lakhs
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name"> Name</label>
                  <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" required />
                </div>
              
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="mobile">Mobile</label>
                  <input type="text" className="form-control" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Enter your mobile number" required />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea className="form-control" id="message" name="message" value={formData.message} onChange={handleChange} rows="4" placeholder="Your message" required></textarea>
                </div>
                <button type="submit" className="btn btn-danger">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DefaultHome;
