import "./App.css"

import Pages from "./Pages"

function App() {
  return <Pages />
  
}

export default App

