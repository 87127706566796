import React from 'react';
import './Brochure.css';
import highwayCapital from '../Brochures/TheHighwayCapitalBroucher.pdf';
import oakField from '../Brochures/OakField_.pdf';
import westernHills from '../Brochures/WesternHillsBrochure.pdf';

const brochures = [
    { name: 'THE HIGHWAY CAPITAL', file: highwayCapital, description: 'A premier development located at a strategic highway junction.' },
    { name: 'Oak Field', file: oakField, description: 'A tranquil community surrounded by lush oak trees.' },
    { name: 'Western Hills', file: westernHills, description: 'An upscale neighborhood nestled in the western hills.' }
];

const BrochureList = () => {
    return (
        <div className="container">
            <div className="header">
                <div className="header-text">
                    <h1>Brick2Brick Projects</h1>
                    <p>Discover our exclusive collection of brochures for our premier real estate developments. Each brochure provides detailed insights into our projects, helping you make informed decisions.</p>
                </div>
            </div>
            <div className="text-center pb-3">
                <h3>Hyderabad</h3>
                <div className="row justify-content-center">
                    {brochures.map((brochure, index) => (
                        <div key={index} className="col-lg-3 col-md-6 mb-5 d-flex align-items-stretch">
                            <div className="pdf-box">
                                <a href={brochure.file} target="_blank" rel="noopener noreferrer">
                                    <div>
                                        <i className="far fa-file-pdf"></i>
                                        <p>{brochure.name}</p>
                                        <p className="description">{brochure.description}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BrochureList;
