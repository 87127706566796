import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Partner.css';

const BusinessAssociateForm = () => {
  const initialFormData = {
    name: '',
    fatherSpouseName: '',
    dob: '',
    age: '',
    bloodGroup: '',
    mobileNo: '',
    alternateNo: '',
    email: '',
    nominee: '',
    relationship: '',
    nomineeAge: '',
    address: '',
    city: '',
    state: '',
    pinCode: '',
    pan: '',
    aadhar: '',
    bankName: '',
    branch: '',
    accountNo: '',
    ifscCode: '',
    sponsorName: '',
    reportingLeader: '',
    termsAccepted: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [showTerms, setShowTerms] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.termsAccepted) {
      alert('Please accept the terms and conditions.');
      return;
    }

    emailjs.sendForm('service_mtdludn', 'template_7zgt4hf', e.target, 'fWM_huievHDIpfX2F')
      .then((result) => {
        alert('Form submitted successfully!');
        setFormData(initialFormData); // Reset the form after successful submission
      }, (error) => {
        alert('An error occurred, please try again.');
      });
  };

  return (
    <form className="business-associate-form" onSubmit={handleSubmit}>
      <h2>Business Associate Enrollment Form</h2>
      <div>
        <label>Name: </label>
        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
      </div>
      <div>
        <label>Father/Spouse Name: </label>
        <input type="text" name="fatherSpouseName" value={formData.fatherSpouseName} onChange={handleChange} required />
      </div>
      <div>
        <label>D.O.B: </label>
        <input type="date" name="dob" value={formData.dob} onChange={handleChange} required />
      </div>
      <div>
        <label>Age: </label>
        <input type="number" name="age" value={formData.age} onChange={handleChange} required />
      </div>
      <div>
        <label>Blood Group: </label>
        <input type="text" name="bloodGroup" value={formData.bloodGroup} onChange={handleChange} required />
      </div>
      <div>
        <label>Mobile No: </label>
        <input type="text" name="mobileNo" value={formData.mobileNo} onChange={handleChange} required />
      </div>
      <div>
        <label>Alternate No: </label>
        <input type="text" name="alternateNo" value={formData.alternateNo} onChange={handleChange} />
      </div>
      <div>
        <label>Email: </label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
      </div>
      <div>
        <label>Nominee: </label>
        <input type="text" name="nominee" value={formData.nominee} onChange={handleChange} required />
      </div>
      <div>
        <label>Relationship: </label>
        <input type="text" name="relationship" value={formData.relationship} onChange={handleChange} required />
      </div>
      <div>
        <label>Nominee Age: </label>
        <input type="number" name="nomineeAge" value={formData.nomineeAge} onChange={handleChange} required />
      </div>
      <div>
        <label>Communication Address: </label>
        <textarea name="address" value={formData.address} onChange={handleChange} required></textarea>
      </div>
      <div>
        <label>City: </label>
        <input type="text" name="city" value={formData.city} onChange={handleChange} required />
      </div>
      <div>
        <label>State: </label>
        <input type="text" name="state" value={formData.state} onChange={handleChange} required />
      </div>
      <div>
        <label>Pin Code: </label>
        <input type="text" name="pinCode" value={formData.pinCode} onChange={handleChange} required />
      </div>
      <div>
        <label>Pan: </label>
        <input type="text" name="pan" value={formData.pan} onChange={handleChange} required />
      </div>
      <div>
        <label>Aadhar: </label>
        <input type="text" name="aadhar" value={formData.aadhar} onChange={handleChange} required />
      </div>
      <div>
        <label>Bank Name: </label>
        <input type="text" name="bankName" value={formData.bankName} onChange={handleChange} required />
      </div>
      <div>
        <label>Branch: </label>
        <input type="text" name="branch" value={formData.branch} onChange={handleChange} required />
      </div>
      <div>
        <label>A/C No: </label>
        <input type="text" name="accountNo" value={formData.accountNo} onChange={handleChange} required />
      </div>
      <div>
        <label>IFSC Code: </label>
        <input type="text" name="ifscCode" value={formData.ifscCode} onChange={handleChange} required />
      </div>
      <div>
        <label>Sponsor's Name: </label>
        <input type="text" name="sponsorName" value={formData.sponsorName} onChange={handleChange} required />
      </div>
      <div>
        <label>Reporting Leader: </label>
        <input type="text" name="reportingLeader" value={formData.reportingLeader} onChange={handleChange} required />
      </div>
      <div className="terms">
        <label>
          <input type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} />
          I accept the <span className="terms-link" onClick={() => setShowTerms(!showTerms)}>terms and conditions</span>
        </label>
        {showTerms && (
          <div className="terms-content">
            <h3>Terms & Conditions</h3>
            <ol>
              <li>Your appointment purely on a commission basis, You will be paid commission per unit or plot or flat sold on the Basic Selling Price as per the company's listed price and the prevalent commission structure if the unit or plot or flat area is more or less than what is listed, the commission will be apportioned accordingly.</li>
              <li>Your services will be under guidance and control of your sponsor, the services include site visit co-ordination, orientation about the project, customer service, support through sales collaterals and various other initiatives which enhance sales.</li>
              <li>Your promotion depends upon your performance in achieving the target. The target will be calculated as individual or overall team sales.</li>
              <li>You are eligible to recruit a team under you and will be paid over-riding commission on your team sales.</li>
              <li>The plots/units have to be sold strictly according to the terms and conditions of the membership application form. Also, you have to achieve the targets as fixed by the company from time to time to be entitled for other benefits Including increase in the basic commissions, travel fund, gifts, business development fund and various other promotions subject To the TDS and other applicable taxes.</li>
              <li>In all the matters not mentioned here in, you shall be governed by the rules and practices of the company related to engagement on commission basis from time to time.</li>
              <li>Any violation of the rules or practices of the company will result in termination of the association without notice, the BA agreement would be cancelled and all the existing and future payments to be made by the company have to be forfeited by the person.</li>
              <li>The senior Business Associate will be completely responsible and liable for cash payments made without obtaining signed official receipts and payments made to unauthorized persons in their respective terms payments should be made strictly at our offices or to our authorized collection executives with photo-ID proofs and against signed official receipts.</li>
              <li>In case of any unavoidable circumstances, the company reserves the right to re-allot designations, targets and make other strategic decisions keeping in mind, the best interests of the company.</li>
              <li>No person is authorized to make any promises or commitment other than those mentioned under the terms and conditions including plot allotment and payment conditions, in case of violation, the company will treat it as an offence and will invite termination of the association.</li>
              <li>The company reserves the right to accept or reject any enrollment form and cancel the Business associate enrollment form without assigning any reasons or any prior notice.</li>
              <li>All kinds of disputes, may be subject to the Jurisdiction of Hyderabad city, Telangana., India only. In case, there are any disputes, the same shall be referred to a Sole Arbitrator to be appointed by the Firm as per the provisions of the Arbitration and Conciliation Act, 1996 and the decision of such Arbitrator shall be final and binding on the parties. The place of Arbitration shall be Hyderabad City only.</li>
              <li>All cheques/DD's must be drawn in favour of M/s Brick2Brick Consulting Pvt Ltd</li>
            </ol>
            <h3>Code of Ethics</h3>
            <ol>
              <li>New enrollment form needs to be validated by team leaders and the respective director of M/s Brick2Brick Consulting Pvt Ltd</li>
              <li>Business Associates Numbers once created is for a lifetime and no changes either in the number or the hierarchy are permitted.</li>
              <li>Offering Discounts, offers, gifts, schemes, etc. on the LISTED PRICE either ONLINE or OFFLINE will be serious damaging the company products brand image.</li>
              <li>Any kind of approaching, tampering & cross information either within the organization or with office staff tolerated under any circumstances and would lead to termination of the association.</li>
              <li>Any kind of data, information or financial misappropriation will be considered a serious offense and will invite action including termination of the association. with Legal Action.</li>
              <li>Sale belongs to the associate who has taken the prospective customer to a site visit investing his or her time and energy with the witnesses as follows:
                <ol type="a">
                  <li>A duly filled "site visit organizing slip" signed by the customer, site coordinator and cab driver.</li>
                  <li>Documented evidence in the site register.</li>
                </ol>
              </li>
            </ol>
          </div>
        )}
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default BusinessAssociateForm;
