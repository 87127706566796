import React from 'react';
import './ProjectCard.css';


function ProjectCard({ project }) {
  return (
    <div className="project-card">
      <img src={project.image} alt={project.title} className="project-image" />
      <h4 className='title'>
        <a href={project.Link}  >{project.title}
          
        </a>
        

      </h4>
      {/* <h2 className="project-title">{project.title}</h2> */}
      <p className="project-developer"><strong>Developer:</strong> {project.developer}</p>
      <p className="project-location"><strong>Location:</strong> {project.location}</p>
      <p className="project-details"><strong>Details:</strong>  {project.details}</p>
      {/* <p className="project-price"> <strong>Price:</strong> {project.price}</p> */}
    </div>
  );
}

export default ProjectCard;
